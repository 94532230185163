<template>
    <div>
        <div id="influencers" class="container" v-if="influencers.length>1">
            <div>
                <div class="row mb-4">
                    <div class="col mx-auto">
                        <div class="tag ml-auto mr-auto">
                            Ambassadors
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col">
                        <h2 class="services-heading">
                            Influencers
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-12" v-for="(influencer) in randomlySelectedInfluencers" :key="influencer._id">
                        <div class="influencer-card" :style="{'background-color':'#FFF6F6'}">
                            <div class="row">
                                <div class="col-auto">
                                    <img class="influencer-avatar mb-2" :src="influencer.profile_photo" alt="">
                                </div>
                                <div class="col text-start">
                                    <div class="influencer-nickname mb-2">
                                        {{ influencer.nickname }}
                                    </div>
                                    <div class="mb-4">
                                        <span v-for="(tag,index) in influencer.tag_name" :key="index">
                                            {{ tag }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="influencer-desc">
                                        {{ influencer.desc }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button @click="swapInfluencers" class="btn btn-branding">
                            Swap
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { collection, query, where, getDocs, getCountFromServer } from "firebase/firestore";


export default {
    name: "Influencers",
    data() {
        return {
            influencers: [],
            fruitColors: ['#FDE68A', '#FFDAB9', '#C5E384', '#FFA07A', '#FFC2C2', '#E6FFB8'],
            refreshKey: 0
        }
    },
    methods: {
        // 拿取所有初级红人
        async getInfluencers() {
            const q = query(collection(this.$db, "influencersDB"), where("intelligent", "==", "初级红人"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                var data = doc.data();
                data.id = doc.id;
                this.influencers.push(data)
            });
        },
        selectRandomFruitColor() {
            const fruitColors = ['#FDE68A', '#FFDAB9', '#C5E384', '#FFA07A', '#FFC2C2', '#E6FFB8'];
            const randomIndex = Math.floor(Math.random() * fruitColors.length);
            return fruitColors[randomIndex];
        },
        swapInfluencers() {
            this.refreshKey++;
        },
        async totalInfluencers() {
            const coll = collection(this.$db, "influencersDB");
            const snapshot = await getCountFromServer(coll);
            console.log('count: ', snapshot.data().count);
        }

    },
    computed: {
        randomlySelectedInfluencers() {
            this.refreshKey;
            var array = [];
            while (array.length < 6) {
                const randomIndex = Math.floor(Math.random() * this.influencers.length);
                if (this.influencers[randomIndex].nickname != '用户已注销') {
                    array.push(this.influencers[randomIndex])
                }
            }
            return array;
        }
    },
    created() {
        this.getInfluencers()
        this.totalInfluencers()

    }
}
</script>
<style scoped>
.influencer-avatar {
    width: 64px;
    height: 64px;
    border-radius: 100%;
}

.influencer-nickname {
    /*    font-family: 'Pacifico', cursive;*/
    font-family: 'ZCOOL KuaiLe', sans-serif;
}

.influencer-desc {}

.influencer-card {
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 30px;
}

.influencer-desc {
    margin-bottom: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
}

.influencer-desc:hover {
    overflow: initial;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
}

.btn-branding {
    background-color: #DF0000;
    color: #FFF3F3;
}

.tag:before {
    background-image: url('/hash.png');
    background-size: 15px 15px;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: -10%;
    /*    vertical-align: middle;*/
    width: 15px;
    height: 15px;
    content: "";
}

.tag {
    background-color: #FFF3F3;
    color: #DF0000;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 56px;
}
</style>