<template>
    <div>
        <div class="container text-start">
            <div class="row" style="margin-bottom: 88px;">
                <div class="col">
                    <img src="../assets/logo.png" alt="">
                </div>
            </div>
            <div class="row mb-4">
                <div class="col">
                    <h1 style="font-weight: 700;">
                        Sign up
                    </h1>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col">
                    <div style="font-size: 12px; line-height: 20px;font-weight: 400;">
                        Please provide your mobile phone number for communication purposes
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <input inputmode="numeric" type="text" class="form-control" placeholder="04XXXXXXXX" v-model="phone">
                </div>
            </div>
            <div class="row  mb-4">
                <div class="col">
                    <input type="checkbox" @click="$bvModal.show('terms-modal')" v-model="agreed"> I agree with the
                    <a href="https://firebasestorage.googleapis.com/v0/b/qrpay-tech.appspot.com/o/documents%2FInfluradar%20employment%20offer%20edit.pdf?alt=media&token=3c28c0f3-441c-4857-aac0-58f093dc7310"
                        target="_blank">
                        terms & conditions
                    </a>
                </div>
            </div>
            <div class="row ">
                <div class="col text-center">
                    <button @click="submit" class="btn btn-danger" :disabled="countDown > 0">
                        {{ countDown == 0 ? "Get verification code" : "Resend code in " + countDown }}
                    </button>
                </div>
            </div>
            <div class="row" v-if="submitted">
                <div class="col text-center">
                    or
                    <br>
                    <a href="https://influradar.com/gmap">
                        Check our product now
                    </a>
                </div>
            </div>
            <div>
                <b-modal id="terms-modal" title="Terms & Conditions" @ok="handleSumit">
                    <div class="influradar-acknowledgement">
                        <h1>Influradar Acknowledgement</h1>

                        <h2>Parties:</h2>
                        <ul>
                            <li>Hash 8 Tech Pty. Ltd (ACN: 655 974 959) of (Address: Building 60, 23 College Walk, Clayton
                                Campus Monash University Melbourne) referred to as ‘We’ ‘Us’ or ‘Influradar’</li>
                            <li>Influencer referred to as ‘you’ or ‘the employee’</li>
                        </ul>

                        <p>Dear Influencer,</p>
                        <p>We are very pleased to offer you a place with Influradar under the terms and conditions as set
                            out in this agreement.</p>

                        <ol>
                            <li><strong>Basis of employment:</strong> Influradar engages you to perform influencer services
                                for Influradar on a casual basis, primarily through social media platforms and other
                                relevant channels, in accordance with the terms and conditions outlined in this Agreement.
                            </li>

                            <li><strong>Scope of work:</strong> You are required to create and share high-quality content
                                related to the Influradar as directed by Influradar. You acknowledge that the quality and
                                effectiveness of your work will be a significant factor in determining the compensation
                                received.</li>

                            <li><strong>Compensation:</strong> Your compensation shall be calculated based on the
                                effectiveness and quality of the work performed. Compensation details and payment structure
                                will be communicated separately and are subject to change at Influradar's discretion. You
                                acknowledge that compensation will be paid in accordance with applicable laws, including but
                                not limited to minimum wage requirements. </li>
                            <li>
                                <strong>Working hours:</strong>
                                Your working hours will be flexible and based on the content creation and promotional needs
                                of Influradar. You acknowledges that the engagement is on a casual basis and does not
                                guarantee a specific number of working hours per week.
                            </li>
                            <li>
                                <strong>
                                    Confidential Information
                                </strong>
                                5.1 All information disclosed by each party to the other party pursuant to this agreement or
                                while negotiating this agreement shall be deemed confidential.
                                <br>
                                5.2 All parties shall keep confidential information strictly confidential. Confidential
                                information may not be used for any purpose other than the performance of this agreement and
                                the services. Do not disclose confidential information to other parties.
                                <br>
                                5.3 Confidential information may also not be disclosed for one year after the termination of
                                this agreement.
                            </li>
                            <li>
                                <strong>
                                    Intellectual Property Ownership
                                </strong>
                                6.1 You acknowledges and agrees that the ownership of all products, systems, know-how,
                                designs, data, and source code related to the Influradar platform and services are owned by
                                Hash 8 Pty. Ltd. This intellectual property includes, but is not limited to, those created
                                during the term of this agreement.
                                <br>
                                6.2 Except as expressly provided in this agreement, you acknowledges and agrees that it has
                                no claim to intellectual property rights and shall not have any license or other rights to
                                use such intellectual property rights.
                            </li>
                            <li>
                                <strong>
                                    Warranty and Disclaimer
                                </strong>
                                Subject to this agreement, both parties acknowledge and agree that all terms and content of
                                the Fair Work Act are excluded from this offer to the fullest extent permitted by law.
                            </li>
                            <li>
                                <strong>Termination</strong>
                                Either Party may terminate this Agreement at any time for convenience or any other reason by
                                giving 15 business days advance written notice to the other parties. If either party
                                breaches the terms of this agreement or causes irreparable damage, the other party may
                                immediately terminate this agreement by written notice unconditionally. During the period
                                from applying for contract termination until approval, all terms of the contract remain in
                                effect. The agreement is valid for one year.
                            </li>
                            <li>
                                <strong>
                                    Data and Privacy
                                </strong>
                                9.1 All data generated by both parties shall become confidential. This information is not
                                permitted to be sold or shared to third parties in any form and for any purpose. This
                                includes but is not limited to user personal data, transaction data, etc.
                                <br>
                                9.2 All information and data generated by both parties may but only be used and analyzed by
                                Influradar to improve service quality.

                            </li>
                            <li>
                                <strong>Indemnity</strong>
                                If any negative impact on Influradar is caused by the your improper operation, including
                                revenue loss, reputation damage, increased cost, etc., you will maintain full indemnity to
                                Influradar and its affiliates.
                            </li>
                            <li>
                                <strong>Dispute Resolution</strong>
                                11.1 A party to the agreement must notify the other party of any dispute related to this
                                agreement as soon as reasonably possible.
                                <br>
                                11.2 Both parties shall endeavour to resolve the dispute in a positive manner within five
                                business days of receipt of the notice of the dispute.
                                <br>
                                11.3 If the parties do not resolve the dispute within five business days, both parties have
                                the
                                right to act if necessary, including legal process.

                            </li>
                        </ol>

                        <p>By signing this contract, you are acknowledging that:</p>
                        <ol>
                            <li>You have reviewed all the contents.</li>
                            <li>You have been given an opportunity to obtain advice concerning its contents and effect.</li>
                            <li>You have understood your obligations.</li>
                        </ol>

                        <p>We look forward to having your continuous support and contributions to our organization as a
                            member of our team.</p>
                        <div class="row">
                            <div class="col">
                                <div class="signature">
                                    <div>
                                        Your Signature:
                                    </div>
                                    <VueSignaturePad :options="{ onBegin: () => { $refs.signaturePad.resizeCanvas() } }"
                                        height="300px" ref="signaturePad" style="border: 1px solid black;" />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'mint-ui';
import { doc, getDoc, setDoc } from "firebase/firestore";
import axios from 'axios';

export default {
    name: "LandingPage",
    data() {
        return {
            phone: '',
            submitted: false,
            countDown: 0,
            agreed: false
        }
    },
    watch: {
        countDown: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.countDown--;
                    }, 1000);
                }

            },
            immediate: true
        }
    },
    methods: {
        submit() {
            const result = this.validatePhoneNumber()
            if (result && this.agreed) {
                this.submitted = true
                this.countDown = 60
                this.addInfluencer()
            } else {
                if (!this.agreed) {
                    Toast("Please agree to the terms and conditions")
                }
                else {
                    Toast("Your phone number is invalid.")
                }
            }

        },
        handleSumit(){
            this.agreed = true
        },
        validatePhoneNumber() {
            const cleanedNumber = this.phone.replace(/\D/g, '');
            // Check if the number is exactly 10 digits long and starts with "04"
            return /^04\d{8}$/.test(cleanedNumber);
        },
        sendVerficationCode(code) {
            axios.get('https://influradar-api.herokuapp.com/email/send-sms', {
                params: {
                    to: "61" + this.phone.slice(1),
                    message: 'Your verification code is:' + code + ' please send it to Influradar official Red account https://verify.influradar.com/red to be verified'
                }
            })
                .then(function (response) {
                    console.log(response)
                    this.$router.push({ name: "LandingPage" })
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        async addInfluencer() {
            const docRef = doc(this.$db, "influencers", this.phone);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                Toast("You already registered, our team member will contact you soon")
                const data = docSnap.data()
                this.sendVerficationCode(data.verificationCode)
            } else {
                // docSnap.data() will be undefined in this case
                // if exists
                const code = Math.floor(Math.random() * 9000) + 1000;
                this.code = code
                await setDoc(docRef, {
                    verified: false,
                    verificationCode: code
                });
                this.sendVerficationCode(code)
                Toast("A four digits code has been sent to your phone, our team member will contact you shortly")
            }
        }
    }
}
</script>
<style scoped>
.container {
    padding: 40px;
    min-height: 100vh;
    background-image: url("../assets/hello.png");
    background-repeat: no-repeat;
    background-position: bottom right;
}

b-modal>>>.modal-content {
    max-height: 90vh;
    text-overflow: hidden;
    overflow: scroll;

}
</style>