<template>
    <div>
        <div class="wrapper" style="padding:0px;">
            <div v-if="this.isDisplayed" @click='closePopUp' class="blur " style="padding:0px;">
            </div>
            <div class="counponPopUp" style="height:auto;width: 100%;">
                <div class="content text-center" v-if="this.isDisplayed">
                    <div class="couponInput " style="padding:20px">
                        <div class="row">
                            <div class="col text-end " @click='closePopUp'>X</div>
                        </div>
                        <div v-if="!messageSent">
                            <div class="validatePhone text-start " style="margin-top:10px;margin-left: 20px;">
                                <div class="form-group " :class="{ 'form-group--error bg-gradient-warning text-danger ': $v.phone.$error }">
                                    <label class="form__label ">Phone</label>
                                    <div class="row">
                                        <div class="col-auto " style="padding-right: 0px;">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">+61</span>
                                            </div>
                                        </div>
                                        <div class="col" style="padding-left: 0px;">
                                            <input v-model="phone" class="form__input" placeholder="PhoneNumber" />
                                        </div>
                                    </div>
                                    <div v-if="submitted">
                                        <div class="error  " v-if="!$v.phone.required">Field is required</div>
                                        <div class="error  " v-if="!$v.phone.minLength">Phone number must have at least {{$v.phone.$params.minLength.min}} letters.</div>
                                        <div class="error " v-if="!$v.phone.numeric">Please enter number.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="couponSubmit text-center" style="margin-top:30px;">
                                <button @click="submit" id="sign-in-button" class="submitButton text-center" type="submit" :disabled="buttonCooldown>0">Submit</button>
                            </div>
                        </div>
                        <!-- if message sent is verification code -->
                        <div v-if="messageSent=='verification'">
                            A verification code has been sent to your phone, please sent your code to our official account
                            <a href="https://verify.influradar.com/red">Click here</a> to be verified
                            you can apply coupon code once your number is verified.
                        </div>
                        <div v-if="messageSent=='notification'">
                            Two coupon codes has been sent to your phone, please do not share your influencer coupon code to others.
                        </div>
                    </div>
                </div>
            </div>
            <RedTemplate @close="closeTemplate" :showModal="showTemplate" :merchant="selectedMerchant" title="title" :paragraph="postTemplate" />
        </div>
    </div>
</template>
<script>
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import { setDoc } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import axios from 'axios';
import RedTemplate from '@/components/RedTemplate';
import { Indicator } from 'mint-ui';

export default {
    props: ['couponCode', 'selectedMerchant'],
    data() {
        return {
            phone: '',
            isDisplayed: true,
            submitStatus: null,
            submitted: false,
            verificationCode: null,
            showTemplate: false,
            postTemplate: "",
            messageSent: false,
            buttonCooldown: 0
        }
    },
    validations: {
        phone: {
            required,
            numeric,
            minLength: minLength(9),
            maxLength: maxLength(10)
        }
    },
    methods: {
        setPhone(value) {
            this.phone = value
            this.$v.phone.$touch()
        },
        closeTemplate() {
            this.showTemplate = false
        },
        closePopUp() {
            this.isDisplayed = false
            this.$emit('closeCouponEvent')
        },
        async checkInlfuencerVerified() {
            const docRef = doc(this.$db, "influencers", this.phone);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("influencer exists")
                // if influencer exists and verified
                if (docSnap.data().verified) {
                    // alert("Your coupon code is 123456")
                    this.sendCouponCode()
                } else {
                    const verificationCode = docSnap.data().verificationCode
                    this.sendVerficationCode(verificationCode)
                    // alert("send verification code")
                }
            } else {
                this.addInfluencer()
                // alert("adding influencer")
            }
        },
        submit() {
            console.log('submit!')
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // if (this.phone.length == 10 && this.phone[0]=='0') {
                //     this.phone = this.phone.slice(1)
                // }
                if (this.phone.length == 9) {
                    this.phone = '0' + this.phone
                }
                Indicator.open();
                this.savePhone()
                this.checkInlfuencerVerified()
                // this.closePopUp()
                // this.addDoc();
            }
        },
        savePhone() {
            localStorage.setItem('phone', this.phone)
        },
        sendVerficationCode(code) {
            var self = this
            axios.get('https://influradar-api.herokuapp.com/email/send-sms', {
                    params: {
                        to: "61" + this.phone,
                        message: 'Your verification code is:' + code + ' please send it to Influradar official Red account https://verify.influradar.com/red to be verified'
                    }
                })
                .then(function(response) {
                    console.log(response)
                    self.messageSent = 'verification'
                    // self.closePopUp()
                    Indicator.close()
                })
                .catch(function(error) {
                    // self.closePopUp()
                    Indicator.close()
                    console.log(error);
                });
        },
        generateTemplate(code) {
            var self = this
            axios.get('https://influradar-api.herokuapp.com/post/generate-template/' + this.selectedMerchant.category.toLowerCase(), {
                    params: {
                        name: this.selectedMerchant.name,
                        address: this.selectedMerchant.address,
                        code: code
                    }
                })
                .then(function(response) {
                    console.log(response.data)
                    self.showTemplate = true
                    self.postTemplate = response.data
                    Indicator.close()

                })
                .catch(function(error) {
                    console.log(error);
                    // self.closePopUp()
                    Indicator.close()
                });
        },
        sendCouponCode() {
            var self = this
            const couponCode = this.addCouponCode()
            couponCode.then((res) => {
                    var influencerCoupon = res.data.find((item) => item.userType == "influencer")
                    var followerCoupon = res.data.find((item) => item.userType == "follower")
                    axios.get('https://influradar-api.herokuapp.com/email/send-sms', {
                            params: {
                                to: "61" + this.phone,
                                message: 'Your one time influencer coupon code for ' + this.selectedMerchant.name + '  is: ' + influencerCoupon.code + ' Please do not share it with others, your followers coupon code is: ' + followerCoupon.code + " Please add it to your post. Redeem coupon codes with QRPAY when you checkout, Thank you."
                            }
                        })
                        .then(function(response) {
                            console.log(response)
                            console.log('message sent')
                            self.messageSent = 'notification'
                            self.generateTemplate(followerCoupon.code)
                        })
                        .catch(function(error) {
                            console.log(error);
                        });

                })
                .catch((err) => {
                    // console.log(err)
                    // self.closePopUp()
                    Indicator.close()
                    this.$swal(err.response.data);
                })


        },
        async addCouponCode() {
            try {
                const result = await axios.get('https://influradar-api.herokuapp.com/coupon/add', {
                    params: {
                        discount: this.selectedMerchant.discount,
                        sessionId: this.selectedMerchant.id,
                        influencerDiscount: this.selectedMerchant.influencerDiscount,
                        influencerId: this.phone
                    }
                })
                return result
            } catch (err) {
                console.log(err.response.data)
                this.$swal(err.response.data.error);
            }

        },
        async addInfluencer() {
            this.verificationCode = Math.floor(1000 + Math.random() * 9000);
            await setDoc(doc(this.$db, "influencers", this.phone), {
                verified: false,
                verificationCode: this.verificationCode
            });
            this.sendVerficationCode(this.verificationCode)

        },
    },
    components: { RedTemplate },
    mounted() {
        if (localStorage.getItem('phone')) {
            this.phone = localStorage.getItem('phone')
        }

    }

}
</script>
<style scoped>
.input-group-text {
    width: 50px;
    height: 30px;
    padding-right: 0px;
    border-right: none;
    border-radius: 5px 0px 0px 5px;
}

.form__input {
    width: 100%;
    height: 30px;
    border-left: none;
    padding: 6px 12px 6px 12px;
    border-radius: 0px 5px 5px 0px;
    border: 0.5px solid grey;
}

.blur {

    height: 100%;
    width: 100%;
    opacity: 0.6;
    background-color: #515151;
    position: fixed;

    z-index: 2;

}

.content {

    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    justify-content: center;

}

.mint-indicator-wrapper {
    z-index: 1000 !important;
}

.mint-indicator-mask {
    z-index: 1000 !important;
}


.couponInput {
    width: 90%;
    height: auto;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%);
    justify-content: center;
    position: fixed;
    border-radius: 20px;
}

.submitButton {
    border-radius: 10px;
    border: 1px solid grey;
    padding: 5px 10px 5px 10px;

}
</style>