<template>
    <div class="container py-4">
        {{ counter / items.length }}
    </div>
</template>
<script>
import page1Data from '@/assets/influencers/page1.json';
import page2Data from '@/assets/influencers/page2.json';
import page3Data from '@/assets/influencers/page3.json';
import page4Data from '@/assets/influencers/page4.json';
import page5Data from '@/assets/influencers/page5.json';
import page6Data from '@/assets/influencers/page6.json';
import page7Data from '@/assets/influencers/page7.json';
import page8Data from '@/assets/influencers/page8.json';
import page9Data from '@/assets/influencers/page9.json';
import page10Data from '@/assets/influencers/page10.json';
import page11Data from '@/assets/influencers/page11.json';
import page12Data from '@/assets/influencers/page12.json';
import page13Data from '@/assets/influencers/page13.json';
import page14Data from '@/assets/influencers/page14.json';
import page15Data from '@/assets/influencers/page15.json';
import { collection, addDoc } from "firebase/firestore";


export default {
    data() {
        return {
            items: [],
            counter: 0
        };
    },
    components: {},
    created() {
        // var self = this;
        this.items = this.items.concat(page1Data.data.list);
        this.items = this.items.concat(page2Data.data.list);
        this.items = this.items.concat(page3Data.data.list);
        this.items = this.items.concat(page4Data.data.list);
        this.items = this.items.concat(page5Data.data.list);
        this.items = this.items.concat(page6Data.data.list);
        this.items = this.items.concat(page7Data.data.list);
        this.items = this.items.concat(page8Data.data.list);
        this.items = this.items.concat(page9Data.data.list);
        this.items = this.items.concat(page10Data.data.list);
        this.items = this.items.concat(page11Data.data.list);
        this.items = this.items.concat(page12Data.data.list);
        this.items = this.items.concat(page13Data.data.list);
        this.items = this.items.concat(page14Data.data.list);
        this.items = this.items.concat(page15Data.data.list);
        // this.items.forEach(item => {
        //     self.addInfluencersToDB(item)
        // })
        console.log(this.items)

    },
    methods: {
        async addInfluencersToDB(influencer) {
            // Add a new document with a generated id.
            const docRef = await addDoc(collection(this.$db, "influencersDB"), influencer);

            if (docRef.id) {
                console.log("Document written with ID: ", docRef.id);
                this.counter+=1
            }
        }
    }
};
</script>
<style scoped>
.profile-image {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.bubbles {
    animation: float 4s ease-in-out infinite;
}

@keyframes float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.beautiful-button {
    background-color: #1e90ff;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 15px 35px;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.beautiful-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.beautiful-button:active {
    transform: translateY(2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 2px rgba(0, 0, 0, 0.23);
}
</style>