<template>
    <div class="footer">
        <div>
            <h2 class="footer-heading">
                <!-- Influradar -->
                <img src="@/assets/logo.png" alt="">
            </h2>
        </div>
        <div class="container">
            <div class="row mb-4">
                <div class="col col-12 mx-auto">
                    <div class="mx-auto" style="display:flex; justify-content: space-between; width: 400px; max-width: 100%;">
                        <div>
                            <a href="#hero">
                                Home
                            </a>
                        </div>
                        <div>
                            <a href="#statistics">
                                Statistics
                            </a>
                        </div>
                        <div>
                            <a href="#about">
                                About
                            </a>
                        </div>
                        <div>
                            <a href="#services">
                                Services
                            </a>
                        </div>
                        <div>
                            <a href="#influencers">
                                Influencers
                            </a>
                        </div>
                        <div>
                            <a href="#posts">
                                Posts
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row mb-4">
                <div class="col-12 text-center ">
                    <a href="https://instagram.com/influradar?igshid=YmMyMTA2M2Y=">
                        <b-icon icon="instagram" aria-hidden="true"></b-icon>
                    </a>
                </div>
            </div> -->
            <div class="row">
                <div class="col">
                    ©2023 Influradar all rights reserved.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Footer",
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.footer {
    padding-top: 100px;
    padding-bottom: 200px;
    background-color: #FFF6F6;
}

.footer a {
    color: inherit;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}
</style>