<template>
    <div>
        <div style="background-color: #ffeee2; height: 300px;">
        </div>
        <div id="posts" class="container text-start" style="margin-top: -300px;">
            <div class="row mb-4">
                <div class="col mx-auto">
                    <div class="tag ml-auto mr-auto">
                        Melbourne Revival
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col text-center">
                    <h1>
                        Posts
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <div class="featured-posts">
                        <div class="featured-posts-tag">
                            <b>Food</b>
                        </div>
                        <img class="featured-posts-img mb-3 shadow" src="../../assets/post1.png" alt="">
                        <div class="author mb-3">
                            <div class="row">
                                <!-- <div class="col-auto">
                                    <img class="author-avatar"
                                        src="https://sns-avatar-qc.xhscdn.com/avatar/5c700619ac05f800017ba3d4.jpg?imageView2/1/w/540/format/jpg"
                                        alt="">
                                </div> -->
                                <!-- <div class="col my-auto">
                                    <div>
                                        <span class="author-info">Chapao Fan, Oct 30, 2020</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="featured-posts">
                        <div class="featured-posts-tag">
                            <b>Food</b>
                        </div>
                        <img class="featured-posts-img mb-3 shadow" src="../../assets/post2.png" alt="">
                        <div class="author mb-3">
                            <div class="row">
                                <!-- <div class="col-auto">
                                    <img class="author-avatar"
                                        src="https://sns-avatar-qc.xhscdn.com/avatar/5c700619ac05f800017ba3d4.jpg?imageView2/1/w/540/format/jpg"
                                        alt="">
                                </div> -->
                                <!-- <div class="col my-auto">
                                    <div>
                                        <span class="author-info">Chapao Fan, Oct 30, 2020</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="featured-posts">
                        <div class="featured-posts-tag">
                            <b>Food</b>
                        </div>
                        <img class="featured-posts-img mb-3 shadow" src="../../assets/post3.png" alt="">
                        <div class="author mb-3">
                            <div class="row">
                                <!-- <div class="col-auto">
                                    <img class="author-avatar"
                                        src="https://sns-avatar-qc.xhscdn.com/avatar/5c700619ac05f800017ba3d4.jpg?imageView2/1/w/540/format/jpg"
                                        alt="">
                                </div> -->
                                <!-- <div class="col my-auto">
                                    <div>
                                        <span class="author-info">Chapao Fan, Oct 30, 2020</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="featured-posts">
                        <div class="featured-posts-tag">
                            <b>Food</b>
                        </div>
                        <img class="featured-posts-img mb-3 shadow" src="../../assets/post4.png" alt="">
                        <div class="author mb-3">
                            <div class="row">
                                <!-- <div class="col-auto">
                                    <img class="author-avatar"
                                        src="https://sns-avatar-qc.xhscdn.com/avatar/5c700619ac05f800017ba3d4.jpg?imageView2/1/w/540/format/jpg"
                                        alt="">
                                </div> -->
                                <!-- <div class="col my-auto">
                                    <div>
                                        <span class="author-info">Chapao Fan, Oct 30, 2020</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Posts"
}
</script>
<style scoped>
@font-face {
    font-family: "Pacifico";
    src: url("/fonts/Pacifico-Regular.ttf") format("truetype");
}

.chapaofan {
    font-family: 'ZCOOL KuaiLe', sans-serif;
}

.chapaofan {
    font-family: 'ZCOOL KuaiLe', sans-serif;
}

.featured-posts-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.featured-posts {
    position: relative;
}

.featured-posts-tag {
    position: absolute;
    top: 20px;
    left: 0;
    background-color: #FF0000;
    color: white;
    padding: 5px 20px 5px 10px;
    border-radius: 0px 30px 30px 0px;
    font-size: 14px;
}

.author-avatar {
    width: 40px;
    height: 40px;
    border: 2px solid #FFC5C5;
    border-radius: 50px;
}

.author-info {
    color: #614D47;
    font-family: "Pacifico";
    font-weight: 500;
    line-height: 1.2em;
}

.post-summary {
    color: #717171;
    font-size: 16px;
    line-height: 1.8em;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.post-summary:hover {
    overflow: initial;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
}


.tag:before {
    background-image: url('/hash.png');
    background-size: 15px 15px;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: -10%;
    /*    vertical-align: middle;*/
    width: 15px;
    height: 15px;
    content: "";
}

.tag {
    background-color: #FFF3F3;
    color: #DF0000;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 56px;
}
</style>