<template>
    <div></div>
</template>
<script>
import page1Data from '@/assets/influencers/au/page1.json';
import page2Data from '@/assets/influencers/au/page2.json';
import page3Data from '@/assets/influencers/au/page3.json';
import page4Data from '@/assets/influencers/au/page4.json';
import page5Data from '@/assets/influencers/au/page5.json';
import page6Data from '@/assets/influencers/au/page6.json';
import page7Data from '@/assets/influencers/au/page7.json';
import page8Data from '@/assets/influencers/au/page8.json';
import page9Data from '@/assets/influencers/au/page9.json';
import page10Data from '@/assets/influencers/au/page10.json';
import page11Data from '@/assets/influencers/au/page11.json';
import page12Data from '@/assets/influencers/au/page12.json';
import page13Data from '@/assets/influencers/au/page13.json';
import page14Data from '@/assets/influencers/au/page14.json';
import page15Data from '@/assets/influencers/au/page15.json';
import page16Data from '@/assets/influencers/au/page16.json';
import page17Data from '@/assets/influencers/au/page17.json';
import page18Data from '@/assets/influencers/au/page18.json';
import page19Data from '@/assets/influencers/au/page19.json';
import page20Data from '@/assets/influencers/au/page20.json';
import page21Data from '@/assets/influencers/au/page21.json';
import page22Data from '@/assets/influencers/au/page22.json';
import page23Data from '@/assets/influencers/au/page23.json';
import page24Data from '@/assets/influencers/au/page24.json';
import page25Data from '@/assets/influencers/au/page25.json';
export default {
    data() {
        return {
            items: []
        }
    },
    created() {
        // var self = this;
        this.items = this.items.concat(page1Data);
        this.items = this.items.concat(page2Data);
        this.items = this.items.concat(page3Data);
        this.items = this.items.concat(page4Data);
        this.items = this.items.concat(page5Data);
        this.items = this.items.concat(page6Data);
        this.items = this.items.concat(page7Data);
        this.items = this.items.concat(page8Data);
        this.items = this.items.concat(page9Data);
        this.items = this.items.concat(page10Data);
        this.items = this.items.concat(page11Data);
        this.items = this.items.concat(page12Data);
        this.items = this.items.concat(page13Data);
        this.items = this.items.concat(page14Data);
        this.items = this.items.concat(page15Data);
        this.items = this.items.concat(page16Data);
        this.items = this.items.concat(page17Data);
        this.items = this.items.concat(page18Data);
        this.items = this.items.concat(page19Data);
        this.items = this.items.concat(page20Data);
        this.items = this.items.concat(page21Data);
        this.items = this.items.concat(page22Data);
        this.items = this.items.concat(page23Data);
        this.items = this.items.concat(page24Data);
        this.items = this.items.concat(page25Data);
        // this.items.forEach(item => {
        //     self.addInfluencersToDB(item)
        // })
        console.log(this.items)

    },
}
</script>