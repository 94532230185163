<template>
    <div class="h-100 d-flex">
        <div class="modal-wrapper d-flex my-auto" v-if="showModal">
            <div class="modal-overlay"></div>
            <div class="modal-content my-auto">
                <div class="modal-header">
                    <h2 class="modal-title">
                        小红书模板 - {{ merchant.category }}
                    </h2>
                    <button class="modal-close" @click="closeModal">X</button>
                </div>
                <div class="modal-body">
                    <div v-html="paragraph" style="text-align: left; height: 400px; overflow: scroll;"></div>
                    <div>
                        <button class="btn mt-4" :class="!copied?'btn-primary':'btn-success'" @click="copyToClipboard">
                            {{ copied?"Copied!":"Copy" }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['title', 'paragraph', 'showModal', 'merchant'],
    data() {
        return {
            copied: false
        }
    },
    methods: {
        closeModal() {
            this.$emit('close')
        },
        copyToClipboard() {
            const el = document.createElement('textarea');
            el.value = this.paragraph;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, 2000);
        }
    }
}
</script>
<style scoped>
.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
}

.modal-content {
    position: relative;
    background: #fff;
    width: 80%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 5px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.modal-close {
    font-size: 1.5rem;
    font-weight: bold;
    border: none;
    background: none;
    cursor: pointer;
}

.modal-body {
    margin-bottom: 20px;
}

.copy-button {
    display: flex;
    align-items: center;
}

.copy-button button {
    background: #0077c0;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
}

.success-message {
    color: green;
    font-size: 1.2rem;
}
</style>