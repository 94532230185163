import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import Profile from '@/views/Profile.vue'
import Ranking from '@/views/Ranking.vue'
import Gmap from '@/views/Gmap.vue'
import List from '@/views/List.vue'
import Sort from '@/components/Sort.vue'
import Catagory from '@/components/Catagory.vue'
import StoreInfor from '@/components/StoreInfor.vue'
import Mapsub from '@/components/Mapsub.vue'
import Influencers from '@/views/Influencers.vue'
// import dataOperation from '@/components/dataOperation.vue'
import dataOperation from '@/components/dataOperation.vue'
import CouponCode from '@/components/CouponCode.vue'
import Validation from '@/views/Validation.vue'
import Invitation from '@/views/Invitation.vue'
import InfluencersData from '@/views/InfluencersData.vue'
import Landing from '@/views/Landing.vue'


Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [{
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/validation',
            name: 'Validation',
            component: Validation
        },
        {
            path: '/ranking',
            name: 'Ranking',
            component: Ranking
        },
        {
            path: '/gmap',
            name: 'Gmap',
            component: Gmap,
            beforeEnter() {
                // Put the full page URL including the protocol http(s) below
                window.location.replace("https://app.influradar.com")
            }
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile
        },
        {
            path: '/list',
            name: 'List',
            component: List
        },
        {
            path: '/sort',
            name: 'Sort',
            component: Sort
        },
        {
            path: '/catagory',
            name: 'Catagory',
            component: Catagory
        },
        {
            path: '/storeInfor',
            name: 'StoreInfor',
            component: StoreInfor
        },
        {
            path: '/mapsub',
            name: 'Mapsub',
            component: Mapsub
        },
        // {
        //   path: '/dataOperation',
        //   name: 'dataOperation',
        //   component: dataOperation
        // },
        {
            path: '/influencers',
            name: 'Influencers',
            component: Influencers
        },
        {
            path: '/dataOperation',
            name: 'dataOperation',
            component: dataOperation
        },
        {
            path: '/couponCode',
            name: 'CouponCode',
            component: CouponCode
        },
        {
            path: '/invitation',
            name: 'Invitation',
            component: Invitation
        },
        {
            path: '/data',
            name: 'InfluencersData',
            component: InfluencersData
        },
        {
            path: '/landing',
            name: 'Landing',
            component: Landing
        }
    ],
})