<template>
    <div id="hero" class="container text-start hero-section">
        <div class="row">
            <div class="col-lg-6 my-auto col-12">
                <div class="tag mb-4">
                    City Revival
                </div>
                <div>
                    <h2 class="heading-title">
                       Making share valuable.
                    </h2>
                </div>
                <div>
                    <p class="heading-desc">
                        Influencer marketing is a cost-effective way for brands to connect with their target audience, build trust through social proof, and achieve measurable results through targeted campaigns. Influencers have loyal followings and their endorsements can influence purchasing decisions.
                    </p>
                </div>
                <div>
                    <button @click="goToTheApp" class="btn btn-discover">
                        Go to the App
                    </button>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div>
                    <img src="../../assets/hero.png" class="influencer-hero" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Hero",
    methods:{
        goToTheApp() {
            this.$router.push({path:'/gmap'})
        }
    }
}
</script>
<style scoped>
.tag {}

.heading-title {
    font-size: 50px;
}

.heading-desc {
    color: #7e8186;
}

.btn-discover {
    background-color: #FF0000;
    color: white;
}

.tag:before {
    background-image: url('/hash.png');
    background-size: 15px 15px;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: -10%;
/*    vertical-align: middle;*/
    width: 15px; 
    height: 15px;
    content:"";
}

.tag {
    background-color: #FFF3F3;
    color: #DF0000;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 56px;
}


.influencer-hero {
    max-width: 100%;
    height: auto;
}
</style>