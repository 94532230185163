<template>
    <div>
        <div class="wrapper">
            <div class="head">
                <div class="row h-100">
                    <div class="col">
                        <div class="search-bar ">
                            <b-input-group>
                                <template #prepend>
                                    <b-input-group-text style="display: block !important;background: white;border-right:none ;border-right: none;border-top-right-radius:0px;border-bottom-right-radius: 0px ;"><svg t="1666765693238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2801" width="15" height="15">
                                            <path d="M469.333333 768c-166.4 0-298.666667-132.266667-298.666666-298.666667s132.266667-298.666667 298.666666-298.666666 298.666667 132.266667 298.666667 298.666666-132.266667 298.666667-298.666667 298.666667z m0-85.333333c119.466667 0 213.333333-93.866667 213.333334-213.333334s-93.866667-213.333333-213.333334-213.333333-213.333333 93.866667-213.333333 213.333333 93.866667 213.333333 213.333333 213.333334z m251.733334 0l119.466666 119.466666-59.733333 59.733334-119.466667-119.466667 59.733334-59.733333z" fill="#444444" p-id="2802"></path>
                                        </svg></b-input-group-text>
                                </template>
                                <b-form-input style="margin-right: 8px;border-left:none ;border-top-right-radius: 8px;border-bottom-right-radius: 8px;" v-model="keyword" placeholder=""></b-form-input>
                                <template #append>
                                    <button @click='tomap' style="border: 0px;padding:0px;margin-left:0px;background:none;">
                                        <b-input-group-text style="display: block !important;background:  #FFEEEB;">
                                            <svg t="1667729765155" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3893" width="15" height="15">
                                                <path d="M640 910.336V197.12a153.6 153.6 0 0 1-22.9888-5.7344L384 113.664v713.216a153.6 153.6 0 0 1 22.9888 5.6832L640 910.336z m51.2 0l195.3792-65.1776a51.2 51.2 0 0 0 35.0208-48.5888V122.2656l-207.4112 69.12a153.6 153.6 0 0 1-22.9888 5.7344v713.216z m-358.4-83.456V113.664L137.4208 178.8928A51.2 51.2 0 0 0 102.4 227.4304v674.304l207.4112-69.12a153.6 153.6 0 0 1 22.9888-5.7344zM121.2416 130.2528l204.8-68.2496a102.4 102.4 0 0 1 64.7168 0l242.4832 80.7936a102.4 102.4 0 0 0 64.7168 0l207.4624-69.12A51.2 51.2 0 0 1 972.8 122.2144v674.3552a102.4 102.4 0 0 1-70.0416 97.1776l-204.8 68.2496a102.4 102.4 0 0 1-64.7168 0l-242.4832-80.7936a102.4 102.4 0 0 0-64.7168 0l-207.4624 69.12A51.2 51.2 0 0 1 51.2 901.7856V227.4304a102.4 102.4 0 0 1 70.0416-97.1776z" fill="#FF2E00" p-id="3894"></path>
                                            </svg>
                                        </b-input-group-text>
                                    </button>
                                </template>
                            </b-input-group>
                        </div>
                    </div>
                </div>
                <div class="row h-100" style="margin-top:16px;">
                    <div class="col-12 text-start">
                        <div class="row h-100">
                            <div class="col-auto mt-auto" style="padding-right: 0px;">
                                <div class="list-filters" id="sort" style="margin-right: 12px;">
                                    <select name="" id="" class="form-select" v-model="sortBy">
                                        <option value="null" disabled>
                                            Sort By
                                        </option>
                                        <option value="discount">
                                            Discount
                                        </option>
                                        <option value="weightedIndex">
                                            Weighted Index
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-auto mt-auto" style="padding-left:0px;padding-right: 0px;">
                                <select name="" id="" class="form-select" v-model="selectedCategory">
                                    <option value="null" disabled>
                                        Filter By
                                    </option>
                                    <option v-for="c in categories" :value="c" :key="c">
                                        {{c}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="storeList" style="margin-top:16px;padding:30px">
            <div class="row " style="margin-top:24px;margin-left: 0px;margin-right: 0px;" v-for='(store,index) in filteredShops' :key='index'>
                <div class="col-auto text-start" style="padding-left:0px;padding-right:0px">
                    <img :src="store.logo?store.logo:'/static/store-img.png'" @click='popup(store.id)' style="width:40px; height: 40px;">
                </div>
                <div class="col-auto mt-auto" style="margin-left:16px;">
                    <div class="row h-100 ">
                        <div class="col text-start " style="padding-left:0px">
                            <div class="store-name no-line-change" @click='popup(store.id)'>{{store.name}}
                            </div>
                        </div>
                    </div>
                    <div class="row h-100 ">
                        <div class="col-12 mt-auto text-start" style="padding-left:0px">
                            <div class="store-addr no-line-change">
                                {{store.address}}
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top:12px">
                        <div class="col-auto mt-auto" style="margin-left: 0px;padding-left: 0px;padding-right: 0px;">
                            <div class="tag mt-auto" id="weightedIndex">
                                <div><span>x</span>{{store.weightedIndex}}</div>
                            </div>
                        </div>
                        <div class="col-auto mt-auto" style="margin-left: 4px;padding-left: 0px;padding-right: 0px;">
                            <div class="tag mt-auto " id="discount">
                                <div>{{store.influencerDiscount}} % off</div>
                            </div>
                        </div>
                        <div class="col-auto mt-auto" style="margin-left: 4px;padding-left: 0px;padding-right: 0px;">
                            <div class="tag mt-auto" id="catagory2">
                                <div>{{store.category}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space1" style="height:1px;margin-top: 24px;background-color:grey;width:100%"> </div>
            </div>
        </div>
        <!-- <div v-if="showSort" class="sorter" style="height: 100%;">
            <div class="bgblur" @click="showSort=false">
            </div>
            <div class="sortshowList">
                <div class="row">
                    <div class="col-auto text-start" style="margin-left:24px;padding-left: 0px;">
                        Sort by
                    </div>
                </div>
                <div class="row" style="margin-top:24px">
                    <div class="col-auto text-start" style="margin-left:24px;padding-left:0px">
                        <div class="sortbtn" @click="selectSort('weightedIndex')" style="padding-top: 16px;padding-bottom: 16px;">By Weighted Index</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto text-start" style="margin-left:24px;padding-left:0px">
                        <div class="sortbtn" @click="selectSort('discount')" style="padding-top: 16px;padding-bottom: 16px;">By Discount</div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="catagorier" v-if="showCatagory" style="width:100%">
            <div class="bgblur" @click='showCatagory=false '>
            </div>
            <div class="categorytable">
                <div class="row" style="margin-top:25px">
                    <div class="col text-start" style="margin-left:24px; ">
                        Category
                    </div>
                    <div class="col text-end" style="margin-right: 21px;">
                        <div class="updateCatagory">
                            <button @click="update" style="border:0px;background: white;color: red;">Update</button>
                        </div>
                    </div>
                </div>
                <div class="checkcatagory text-start">
                    <div class="space1" style="height:1px;background:grey;margin-bottom:17.5px;margin-top:20px ;width: 100vw;"> </div>
                    <div class="row">
                        <div class="col">
                            <div style="margin-bottom: 17.5px;margin-left: 24px;">
                                <input type="checkbox" v-model="selectAll">
                                <label for="" style="margin-left:12px">Select All</label></div>
                            <div v-for="(t,index) in types" :key="index" style="margin-bottom: 17.5px;">
                                <div class="space1" style="height:1px;background:grey;margin-bottom:17.5px;margin-bottom:17.5px ;width: 100vw;"> </div>
                                <input style="margin-left: 24px;" type="checkbox" :value="t" v-model="selected">
                                <label for="" style="margin-left:12px">
                                    <div @click="selectedCategory=t">
                                        {{t}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script type="text/javascript">
import { collection, getDocs } from "firebase/firestore";
// import Catagory from '@/components/Catagory.vue'
// import Sort from '@/components/Sort.vue'
export default {
    name: 'List',
    data() {
        return {



            showSort: false,
            showCatagory: false,
            keyword: null,
            sortBy: null,
            filterBy: null,
            isUpdated: false,
            stores: [],
            selectedList: [],



            selected: [],
            status: 'not_accepted',
            categories: ['food', 'entertainment', 'beauty', 'service', 'healthy', 'fashion'],
            selectAll: false,
            selectedCategory: null



        }
    },
    mounted() {
        this.getAllDocs();

    },
    computed: {
        filteredShops() {
            const self = this
            const shops = this.stores
            let result = shops
            if (this.keyword) {
                self.selectedCategory = null
                result = shops.filter(shop => shop.name.toLowerCase().includes(self.keyword.toLowerCase()))
            }
            if (this.selectedCategory) {
                self.keyword = null
                result = shops.filter(shop => shop.category.toLowerCase() == self.selectedCategory.toLowerCase())
            }
            result = result.sort((a,b)=>a.name.localeCompare(b.name))
            if (self.sortBy == 'discount') {
                result = result.sort((a, b) => b.influencerDiscount - a.influencerDiscount)
            }
            if (self.sortBy == 'weightedIndex') {
                result = result.sort((a, b) => b.weightedIndex - a.weightedIndex)
            }
            return result

        }

    },
    methods: {
        tomap() {
            this.$router.push('/Gmap')

        },
        Sort() {
            if (!this.showCatagory) { this.showSort = true; }

        },
        Catagory() {
            if (!this.showSort) { this.showCatagory = true; }

        },
        selectSort(type) {
            console.log(type)
            this.sortBy = type
        },
        update() {
            this.isUpdated = true;
            this.selectedList = this.selected;
            console.log(this.isUpdated);
        },
        async getAllDocs() {
            const querySnapshot = await getDocs(collection(this.$db, "shops"));
            var initDBList = [];

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                var data = doc.data();
                data.id = doc.id
                initDBList.push(data);
                // console.log(this.currentLocation)
                this.stores = initDBList;
            });

        },
        popup(id) {
            this.$router.push({
                path: '/Profile',
                query: {
                    id: id
                }
            })
        },

    },


    watch: {
        selected(n) {
            if (n.length == this.types.length) {
                this.selectAll = true;
            }
        },
        selectAll(n) {
            if (n) {
                this.selected = this.types
            } else {
                this.selected = []
            }
        }
    },




    components: {
        // Sort,
        // Catagory
    }
}
</script>
<style scoped>
.wrapper {

    width: 100%;

    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    top: 72px;
    z-index: 100;
    /*left: 30px;*/

    /*padding-right: 30px;*/



}

.bgblur {

    height: 100vh;

    width: 100vw;
    /*    filter: blur(200px);*/
    opacity: 0.6;
    background-color: #515151;

    position: relative;

}

.sortshowList {
    padding: 24px;
    height: 261px;
    bottom: 0px;
    color: #2E2E2E;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    position: fixed;
    width: 100%;
    background-color: white;
}

/*
.head {

    position: fixed;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 30px;
    top: 72px;


}*/
.search-bar {
    margin-top: 72px;
    height: 48px;

}

.tag {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 2px 8px;
    border-radius: 33px;
}

.sorter {
    position: fixed;
    bottom: 0px;


    width: 100vw;


}

.categorytable {
    position: fixed;
    bottom: 0px;
    background-color: white;
    height: 325px;


    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E
}

.catagorier {
    position: fixed;
    bottom: 0px;


    width: 100vw;


}

.catagories {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.store-name {

    font-weight: 700;
    font-size: 18px;
    line-height: 21px;

}

.store-address {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;

}

.list-filters {
    background-color: #F2F2F2;
    border-radius: 22px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E;

}

#catagory1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

#weightedIndex {
    background-color: #FF2E00;
    color: #FFFFFF;
}

#discount {
    background-color: #FFEEEB;
    color: #FF2E00;
}

.sortbtn {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px
}

#catagory2 {
    background-color: #F2F2F2;
    color: #7E7E7E;
}

.no-line-change {
    white-space: nowrap;
    max-width: 200px;

    text-overflow: ellipsis;
    overflow: hidden;
}

#b-form-group {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}
</style>